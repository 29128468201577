import { CreateOrderForm } from "features/create-order";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Paths } from "shared/config";
import { Container } from "shared/ui";

import { newOrderPageLoader } from "./loader";

export const PageNewOrder = () => {
  const { defaultTradeOutletId, tradeOutlets } = useLoaderData() as Awaited<
    ReturnType<typeof newOrderPageLoader>
  >;

  const navigate = useNavigate();

  const handleTradeOutletButtonClick = () => {
    navigate(Paths.Outlets);
  };

  return (
    <Container
      sx={{
        py: 2,
      }}
    >
      <CreateOrderForm
        defaultTradeOutletId={defaultTradeOutletId}
        onTradeOutletButtonClick={handleTradeOutletButtonClick}
        tradeOutlets={tradeOutlets}
      />
    </Container>
  );
};
