import { SnackbarProvider as BaseSnackbarProvider } from "@mychili/ui-web";

export type SnackbarProviderProps = {
  children: React.ReactNode;
};

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  return (
    <BaseSnackbarProvider
      autoHideDuration={3000}
      preventDuplicate
      maxSnack={3}
      dense={false}
      hideIconVariant
    >
      {children}
    </BaseSnackbarProvider>
  );
};
