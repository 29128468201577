import {
  Box,
  Dialog,
  Drawer,
  IconButton,
  IconClose,
  Show,
  Stack,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mychili/ui-web";
import { PropsWithChildren } from "react";

const Container = ({ children }: PropsWithChildren) => (
  <Stack m={2}>{children}</Stack>
);

const ActionSheetDragIndicator = () => {
  const theme = useTheme();
  return (
    <Box
      position="sticky"
      top={0}
      left={0}
      right={0}
      pb={2}
      display="flex"
      justifyContent="center"
    >
      <Box
        width={32}
        height={4}
        bgcolor={theme.palette.neutral[80]}
        borderRadius={16}
      />
    </Box>
  );
};

type ActionSheetHeaderProps = {
  title?: string;
  onClose: () => void;
  hasCloseButtonOnMobile?: boolean;
};
const ActionSheetHeader = ({
  title,
  onClose,
  hasCloseButtonOnMobile = false,
}: ActionSheetHeaderProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack direction="row" justifyContent="space-between" mb={2}>
      <Typography variant="bigTextMedium" textAlign="center">
        {title}
      </Typography>
      <Show when={isDesktop || hasCloseButtonOnMobile}>
        <IconButton
          onClick={onClose}
          sx={{
            marginLeft: "auto",
            height: 24,
            width: 24,
            color: "common.black",
          }}
        >
          <IconClose />
        </IconButton>
      </Show>
    </Stack>
  );
};

export type ActionSheetProps = {
  children?: React.ReactNode;
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen?: () => void;
  isSwipeable?: boolean;
  hasCloseButtonOnMobile?: boolean;
  maxHeight?: number;
};
export const ActionSheet = ({
  children,
  isOpen,
  title,
  isSwipeable = true,
  onClose,
  hasCloseButtonOnMobile = false,
  onOpen = () => {},
  maxHeight,
}: ActionSheetProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const backdropProps = {
    sx: { background: theme.palette.common.black, opacity: "0.7 !important" },
  };
  const paperProps = {
    sx: {
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      maxHeight: `calc(${maxHeight || 100}% - 8px)`,
    },
  };

  if (isDesktop) {
    return (
      <Dialog
        slotProps={{
          backdrop: backdropProps,
        }}
        fullWidth={true}
        onClose={onClose}
        open={isOpen}
      >
        <Container>
          <ActionSheetHeader title={title} onClose={onClose} />
          <Box>{children}</Box>
        </Container>
      </Dialog>
    );
  }

  if (isSwipeable) {
    return (
      <SwipeableDrawer
        anchor="bottom"
        slotProps={{
          backdrop: backdropProps,
        }}
        disableSwipeToOpen={true}
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
        PaperProps={paperProps}
      >
        <Container>
          <ActionSheetDragIndicator />
          <ActionSheetHeader
            title={title}
            onClose={onClose}
            hasCloseButtonOnMobile={hasCloseButtonOnMobile}
          />
          <Box>{children}</Box>
        </Container>
      </SwipeableDrawer>
    );
  }

  return (
    <Drawer
      anchor="bottom"
      slotProps={{
        backdrop: backdropProps,
      }}
      onClose={onClose}
      open={isOpen}
      PaperProps={paperProps}
    >
      <Container>
        <ActionSheetHeader
          title={title}
          onClose={onClose}
          hasCloseButtonOnMobile={hasCloseButtonOnMobile}
        />
        <Box>{children}</Box>
      </Container>
    </Drawer>
  );
};
