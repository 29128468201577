import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetTradeOutletsResponseData = {
  agreementId: bnplMerchants.TradeOutletExcludingArchivedCreatedUpdated["agreement_id"];
  id: bnplMerchants.TradeOutletExcludingArchivedCreatedUpdated["id"];
  isOnline: bnplMerchants.TradeOutletExcludingArchivedCreatedUpdated["is_online"];
  location: bnplMerchants.TradeOutletExcludingArchivedCreatedUpdated["location"];
  locationGeo: bnplMerchants.TradeOutletExcludingArchivedCreatedUpdated["location_geo"];
  merchantId: bnplMerchants.TradeOutletExcludingArchivedCreatedUpdated["merchant_id"];
  name: bnplMerchants.TradeOutletExcludingArchivedCreatedUpdated["name"];
}[];

export const getTradeOutlets =
  async (): Promise<GetTradeOutletsResponseData> => {
    const response =
      await bnplMerchants.getApiMerchantsServiceEmployeeTradeOutlets();

    return (
      response.data.data?.map((item) => {
        return {
          agreementId: item.agreement_id,
          id: item.id,
          isOnline: item.is_online,
          location: item.location,
          locationGeo: item.location_geo,
          merchantId: item.merchant_id,
          name: item.name,
        };
      }) || []
    );
  };
