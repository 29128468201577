import { setClipboardString } from "shared/lib/clipboard";

import {
  BaseTooltip,
  IconButton,
  IconCopy,
  Stack,
  SvgIconProps,
  Typography,
} from "..";

export type InfoBlockProps = {
  title: string;
  description: string;
  Icon?: (props: SvgIconProps) => JSX.Element;
  canCopy?: boolean;
  onCopy?: () => void;
  variant?: "column" | "row";
};

export const InfoBlock = ({
  title,
  description,
  Icon,
  canCopy = false,
  onCopy,
  variant,
}: InfoBlockProps) => {
  const handleCopyClick = () => {
    setClipboardString(description);

    if (onCopy) onCopy();
  };

  return (
    <Stack
      p={1}
      px={variant === "row" ? 1.5 : 1}
      spacing={0.5}
      borderRadius={1}
      flex={1}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ border: "1px solid", borderColor: "neutral.95" }}
    >
      <Stack
        direction={variant}
        sx={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" spacing={0.5} alignItems="center">
          {Icon && <Icon fontSize="small" sx={{ color: "neutral.50" }} />}
          <Typography
            variant="smallTextRegular"
            color="neutral.50"
            whiteSpace="nowrap"
          >
            {title}
          </Typography>
        </Stack>
        <Typography variant="regularTextMedium">{description}</Typography>
      </Stack>

      {canCopy && (
        <BaseTooltip title="Copy" placement="top">
          <div>
            <IconButton onClick={handleCopyClick} color="gray" size="small">
              <IconCopy />
            </IconButton>
          </div>
        </BaseTooltip>
      )}
    </Stack>
  );
};
